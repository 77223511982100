// Device Grid Options
$small: 576px;
$medium: 768px;
$large: 992px;
$extra_large: 1200px;


// Positioning rules
$max-value: 500;
@mixin generate-margins($direction) {
  @for $i from -50 through $max-value {
    .margin-#{$direction}-#{$i} {
      margin-#{$direction}: #{$i}px!important;
    }
  }
}

@mixin margin-x {
  @for $i from 1 through $max-value {
    .margin-#{$i} {
      margin: #{$i}px!important;
    }
  }
}

@include margin-x
@include generate-margins("top")
@include generate-margins("left")
@include generate-margins("right")
@include generate-margins("bottom")

@mixin generate-paddings($direction) {
  @for $i from 1 through $max-value {
    .padding-#{$direction}-#{$i} {
      padding-#{$direction}: #{$i}px!important;
    }
  }
}

@mixin padding-x {
  @for $i from 1 through $max-value {
    .padding-#{$i} {
      padding: #{$i}px!important;
    }
  }
}

@include padding-x
@include generate-paddings("top")
@include generate-paddings("left")
@include generate-paddings("right")
@include generate-paddings("bottom")



// Percent width dynamic values
$min-value: 1;
@mixin generate-widths{
  @for $i from $min-value through 100 {
    .width-#{$i} {
      width: #{$i}#{'%'}!important;
      flex-basis: #{$i}#{'%'}!important;
      max-width: #{$i}#{'%'}!important;
    }
  }
}

@include generate-widths


// Fixed width dynamic values
$min-value: 1;
@mixin generate-fixed-widths{
  @for $i from $min-value through 1000 {
    .wd-#{$i} {
      width: #{$i}#{'px'}!important;
      flex-basis: #{$i}#{'px'}!important;
      max-width: #{$i}#{'px'}!important;
    }
  }
}

@include generate-fixed-widths


// Fixed width dynamic values
$min-value: 1;
@mixin generate-fixed-height{
  @for $i from $min-value through 1000 {
    .height-#{$i} {
      height: #{$i}#{'px'}!important;
      max-height: #{$i}#{'px'}!important;
    }
  }
}

@include generate-fixed-height


// Fixed opacity values
$min-value: 1;
@mixin generate-opacities{
  @for $i from $min-value through 9 {
    .opacity-#{$i} {
      opacity: #{'.'}#{$i}!important;
    }
  }
}

@include generate-opacities


// Fixed width dynamic values
$min-value: 1;
@mixin generate-fixed-min-widths{
  @for $i from $min-value through 1000 {
    .min-wd-#{$i} {
      min-width: #{$i}#{'px'} !important;
    }
  }
}

@include generate-fixed-min-widths

// Fixed width dynamic values
$min-value: 1;
@mixin generate-fixed-max-widths{
  @for $i from $min-value through 1000 {
    .max-wd-#{$i} {
      max-width: #{$i}#{'px'} !important;
    }
  }
}

@include generate-fixed-min-widths